import {
  Card,
  Col,
  Progress,
  Radio,
  Row,
  Select,
  Skeleton,
  Tabs,
  Typography,
  Descriptions,
  Table,
  DatePicker,
  Button,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../context/AuthContext";
import { formatPhone } from "../../helper/functions";
import lang from "../../helper/langHelper";
import SectionWrapper from "../../components/SectionWrapper";
import TopServiceProvider from "./_TopServiceProvider";
import TopUsedCategory from "./_TopUsedCategory";
import TopReportProvider from "./_TopReportProvider";
import MostViewProvider from "./_MostViewProvider";
import MostViewServices from "./_MostViewServices";
import ServicesPerCategory from "./_ServicesPerCategory";
import BannerClicks from "./_BannerClicks";
import moment from "moment";
import MonthlyChartSection from "./_MonthlyChartSection";
import QuarterlyChartSection from "./_QuartlyChartSection";
import WeeklyChartSection from "./_WeeklyChartSection";
import { UndoOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const years = [2024];

function Home() {
  const { setPageHeading, country } = useContext(AppStateContext);

  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(years[0]);   
  const [filter, setFilter] = useState("State");
  const { userProfile, isAdmin, setRefreshUserProfile } = useAuthContext();
  const [dashboard, setDashboard] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
    total: 0,    
  });

  const [dataFilter, setDataFilter] = useState({
    custom_date: undefined,
    key: "life_time",
  });

  const count = [
    {
      today: lang("Number of Users"),
      title: `${dashboard && dashboard ? dashboard.totalCustomer : 0}`,
      persent: "10%",
      icon: <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: "/customer",
      key: "totalCustomer",
      _7Day: dashboard && (dashboard.user7 ?? 0),
      _14Day: dashboard && (dashboard.user14 ?? 0),
      description : "Total number of users registered on the platform."
    },
    {
      today: lang("Number of Active Users"),
      title: `${
        dashboard && dashboard.activeCustomer ? dashboard.activeCustomer : 0
      }`,
      icon: <i class="fas fa-info-circle"></i>,   
      bnb: "bnb2",
      url: `/customer?status=${true}`,
      key: "activeCustomer",
      _7Day: dashboard && (dashboard.activeUser7 ?? 0),
      _14Day: dashboard && (dashboard.activeUser14 ?? 0),
      description : "Total number of active users registered on the platform." ,
    },
    {
      today: lang("Number of Service Provider"),
      title: `${
        dashboard && dashboard.totalServiceProvider
          ? dashboard.totalServiceProvider
          : 0
      }`,

      icon: <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: `/service-provider`,
      key: "activeCustomer",
      _7Day: dashboard && (dashboard.activeUser7 ?? 0),
      _14Day: dashboard && (dashboard.activeUser14 ?? 0),
      description : "Total service providers currently registered." ,
    },
    {
      today: lang("Number of Categories"),
      title: `${dashboard && dashboard ? dashboard.totalCategories : 0}`,
      persent: "10%",
      icon:  <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: "/category",
      key: "totalCustomer",
      _7Day: dashboard && (dashboard.user7 ?? 0),
      _14Day: dashboard && (dashboard.user14 ?? 0),
      description : "Total number of service categories available." ,
    },
    {
      today: lang("Number of Created Events"),
      title: `${
        dashboard && dashboard.totalEventType ? dashboard.totalEventType : 0
      }`,
      icon:<i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: "/event-type",
      key: "totalOrder",
      _7Day: dashboard && (dashboard.order7 ?? 0),
      _14Day: dashboard && (dashboard.order14 ?? 0),
      description : "Total events created by users on the platform." ,
    },
    {
      today: lang("Number of User Events"),
      title: `${dashboard && dashboard.userEvent ? dashboard.userEvent : 0}`,
      icon:<i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: "/customer",
      key: "userEvent",
      // _7Day: dashboard && (dashboard.order7 ?? 0),
      // _14Day: dashboard && (dashboard.order14 ?? 0),
      description : "Events created by users for personal or public purposes." ,
    },
    {
      today: lang("Number of Services"),
      title: `${
        dashboard && dashboard.totalServices ? dashboard.totalServices : 0
      }`,
      icon: <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: "/service",
      key: "totalDriver",
      _7Day: dashboard && (dashboard.driver7 ?? 0),
      _14Day: dashboard && (dashboard.driver14 ?? 0),
      description : "Total number of services offered by providers." ,
    },
    // {
    //   today: "Number of Services per Category",
    //   title: `${
    //     dashboard && dashboard.totalDriver ? dashboard.totalDriver : 0
    //   }`,
    //   icon: <i class="fas fa-info-circle"></i>,
    //   bnb: "bnb2",
    //   url: "/category",
    //   key: "category",
    //   _7Day: dashboard && (dashboard.driver7 ?? 0),
    //   _14Day: dashboard && (dashboard.driver14 ?? 0),
    // },
    {
      today: lang("Number of Requested Quotation"),
      title: `${dashboard && dashboard.quote ? dashboard.quote : 0}`,
      icon: <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: "/quotation-request",
      key: "totalRestaurant",
      _7Day: dashboard && (dashboard.restaurant7 ?? 0),
      _14Day: dashboard && (dashboard.restaurant14 ?? 0),
      description : "Quotations requested by users for various services." ,
    },
    {
      today: lang("Number of Replied Quotation"),
      title: `${dashboard && dashboard.quoteReply ? dashboard.quoteReply : 0}`,
      icon: <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: "/quotation-request",
      key: "totalSales",
      _7Day: dashboard && (dashboard.restaurant7 ?? 0),
      _14Day: dashboard && (dashboard.restaurant14 ?? 0),
      description : "Quotations that have been replied to by service providers." ,
    },
    {
      today: lang("Number of Support Cases"),
      title: `${
        dashboard && dashboard.totalSupport ? dashboard.totalSupport : 0
      }`,
      icon: <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      url: `/support`,
      key: "totalDriver",
      _7Day: dashboard && (dashboard.driver7 ?? 0),
      _14Day: dashboard && (dashboard.driver14 ?? 0),
      description : "Total support cases raised by users." ,
    },
    {
      today: lang("Number of Active Visits"),
      title: `${
        dashboard && dashboard.activeVisits ? dashboard.activeVisits : 0
      }`,
      icon: <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      key: "totalDriver",
      _7Day: dashboard && (dashboard.driver7 ?? 0),
      _14Day: dashboard && (dashboard.driver14 ?? 0),
      description : "Currently active user visits on the platform." ,
    },
    {
      today: lang("Number of Total Visits"),
      title: `${
        dashboard && dashboard.totalVisits ? dashboard.totalVisits : 0
      }`,
      icon: <i class="fas fa-info-circle"></i>,
      bnb: "bnb2",
      key: "totalDriver",
      _7Day: dashboard && (dashboard.driver7 ?? 0),
      _14Day: dashboard && (dashboard.driver14 ?? 0),
      description : "Total visits recorded on the platform." ,
    },
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setYear(value);
  };

  useEffect(() => {
    setPageHeading(lang("Welcome Admin"));
    if (!userProfile) return;

    if (userProfile.type == "SubAdmin") {
      setPageHeading(`${lang("Welcome")} ${userProfile.name}`);
      if (!userProfile?.role_id?.permission?.includes("dashboard-management"))
        return;
    }

    const queryString = Object.entries(dataFilter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    setLoading(true);
    request({
      url:
        apiPath.dashboard +
        `?year=${year ? year : "2024"}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setDashboard(data.data);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, [year, dataFilter, country.country_id, userProfile]);

  const onChange = (key, value) => {
    console.log(key, "key...");
    if (key === "custom_date") {
      setDataFilter((prev) => ({ ...prev, [key]: value, key: "life_time" }));
    } else if (key === "key") {
      setDataFilter((prev) => ({
        ...prev,
        [key]: value,
        custom_date: undefined,
      }));
    } else {
      setDataFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  return (
    <>
      {userProfile &&
      !userProfile?.role_id?.permission?.includes("dashboard-management") &&
      userProfile.type == "SubAdmin" ? (
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Descriptions
              column={{ xs: 16, sm: 16, md: 24 }}
              title={lang("Sub Admin Info")}
            >
              <Descriptions.Item label={lang("Name")}>
                {userProfile?.name}
              </Descriptions.Item>
              <Descriptions.Item label={lang("Role title")}>
                {userProfile?.role_id?.name}
              </Descriptions.Item>
              <Descriptions.Item label={lang("Mobile Number")}>
                {formatPhone(
                  userProfile?.country_code,
                  userProfile?.mobile_number
                )}
              </Descriptions.Item>
              {/* <Descriptions.Item label={lang("Cities")}>
                {userProfile?.city_ids.map((item) => item.name).join(", ")}
              </Descriptions.Item> */}
            </Descriptions>
          </Col>
        </Row>
      ) : (
        <div className="page-top-space home-card layout-content">
          <div className="mb-24">
            <div className="sub_title">
              <p>{lang("Here is the information about all your business")}</p>
              {/* <div className="bussiness_year">
                <span>{lang("Year")}</span>
                <Select
                  value={year}
                  style={{ width: 120 }}
                  onChange={handleChange}
                  options={years.map((item) => ({ value: item, label: item }))}
                />
              </div> */}
              <div className="bussiness_year bussiness_year-main-67">
                <span>{lang("Filter")}</span>
                <Select
                  value={filter}
                  style={{ width: 120 }}
                  onChange={(e) => setFilter(e)}
                  options={[
                    { label: lang("State"), value: "State" },
                    { label: lang("Graph"), value: "Graph" },
                  ]}
                />
              </div>
            </div>

            {filter === "State" ? (
              <>
                <div className="  sub_title justify-content-end">
                  <div className="bussiness_year">
                    <Select
                      value={dataFilter.key}
                      style={{ width: 120 }}
                      onChange={(value) => {
                        onChange("key", value);
                      }}
                      className="main-option-hh5"
                      options={[
                        { value: "today", label: lang("Today") },
                        { value: "yesterday", label: lang("Yesterday") },
                        { value: "last_week", label: lang("Last Week") },
                        { value: "last_month", label: lang("Last Month") },
                        { value: "last_year", label: lang("Last Year") },
                        { value: "life_time", label: lang("Life Time") },
                      ]}
                    />
                  </div>
                  <div className="bussiness_year">
                    <DatePicker
                      allowClear={false}
                      style={{ width: 120 }}
                      value={
                        dataFilter?.custom_date
                          ? moment(dataFilter?.custom_date)
                          : ""
                      }
                      placeholder={lang("Custom Date")}
                      onChange={(value) => {
                        onChange(
                          "custom_date",
                          moment(value).format("YYYY-MM-DD")
                        );
                      }}
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setDataFilter({
                        custom_date: undefined,
                        key: "life_time",
                      });
                    }}
                    type="primary"
                    icon={<UndoOutlined />}
                  >
                    {lang("Reset")}
                  </Button>
                </div>
                <Row gutter={[24, 0]}>
                  <Col xs={24} sm={24} md={24}>
                    <Row
                      className="rowgap-vbox"
                      gutter={[24, 16]}
                      // style={{ marginLeft: "0" }}
                    >
                      {count.map((c, index) => (
                        <Col
                          key={index}
                          xs={24}
                          sm={24}
                          md={12}
                          lg={12}
                          xl={8}
                          xxl={6}
                          className="mb24"
                        >
                          <CountCard c={c} key={index} loading={loading} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3" gutter={[24, 0]} >
                  <Col xs={24} xl={12} className="  " >
                    <SectionWrapper
                      cardHeading={lang("Top service providers")}
                      extra={
                        <>
                          <div className="w-100 text-head_right_cont">
                            <div className="pageHeadingSearch desh-input-search"></div>
                          </div>
                        </>
                      }
                    >
                      <TopServiceProvider
                        data={dashboard?.topProvider}
                        loading={loading}
                      />
                    </SectionWrapper>
                  </Col>

                  <Col
                    xs={24}
                    xl={12}
                    className="sm-padding-0 "
                    style={{ height: "100%" }}
                  >
                    <SectionWrapper
                      cardHeading={lang("Most viewed Service Provider")}
                      extra={
                        <>
                          <div className="w-100 text-head_right_cont">
                            <div className="pageHeadingSearch desh-input-search"></div>
                          </div>
                        </>
                      }
                    >
                      <MostViewProvider
                        data={dashboard?.viewProvider}
                        loading={loading}
                      />
                    </SectionWrapper>
                  </Col>
                </Row>

                <Row gutter={[24, 0]}>
                  <Col
                    xs={24}
                    xl={12}
                    className="sm-padding-0 "
                    style={{ height: "100%" }}
                  >
                    <SectionWrapper
                      cardHeading={lang("Top reported Service Providers")}
                      extra={
                        <>
                          <div className="w-100 text-head_right_cont">
                            <div className="pageHeadingSearch desh-input-search"></div>
                          </div>
                        </>
                      }
                    >
                      <TopReportProvider
                        data={dashboard?.reportedProvider}
                        loading={loading}
                      />
                    </SectionWrapper>
                  </Col>

                  <Col xs={24} xl={12} className="sm-padding-0 ">
                    <SectionWrapper
                      cardHeading={lang("Most viewed Services")}
                      extra={
                        <>
                          <div className="w-100 text-head_right_cont"></div>
                        </>
                      }
                    >
                      <MostViewServices
                        data={dashboard?.viewServices}
                        loading={loading}
                      />
                    </SectionWrapper>
                  </Col>

                  <Col xs={24} xl={12} className="sm-padding-0 ">
                    <SectionWrapper
                      cardHeading={lang("Services per category")}
                      extra={
                        <>
                          <div className="w-100 text-head_right_cont"></div>
                        </>
                      }
                    >
                      <ServicesPerCategory
                        data={dashboard?.servicePerCategory}
                        loading={loading}
                      />
                    </SectionWrapper>
                  </Col>

                  <Col xs={24} xl={12} className="sm-padding-0 ">
                    <SectionWrapper
                      cardHeading={lang("Top used Categories")}
                      extra={
                        <>
                          <div className="w-100 text-head_right_cont">
                            <div className="pageHeadingSearch desh-input-search"></div>
                          </div>
                        </>
                      }
                    >
                      <TopUsedCategory
                        data={dashboard?.topCategory}
                        loading={loading}
                      />
                    </SectionWrapper>
                  </Col>
                  
                  <Col xs={24} xl={24} className="sm-padding-0 ">
                    <SectionWrapper
                      cardHeading={lang("Banner Click's")}
                      extra={
                        <>
                          <div className="w-100 text-head_right_cont"></div>
                        </>
                      }
                    >
                      <BannerClicks
                        data={dashboard?.banner}
                        loading={loading}
                      />
                    </SectionWrapper>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col xs={24} xl={24} lg={24}>
                    <Tabs className="main_tabs">
                      <TabPane tab={lang("weekly")} key="Weekly">
                        <div style={{ padding: "25px" }}>
                          <Row gutter={[24, 0]}>
                            <WeeklyChartSection borderColor="#1EB564" />
                          </Row>
                        </div>
                      </TabPane>
                      <TabPane tab={lang("monthly")} key="Monthly">
                        <div style={{ padding: "25px" }}>
                          <Row gutter={[24, 0]}>
                            <MonthlyChartSection borderColor="#1EB564" />
                          </Row>
                        </div>
                      </TabPane>

                      <TabPane tab={lang("quarterly")} key="Quarterly">
                        <div style={{ padding: "25px" }}>
                          <Row gutter={[24, 0]}>
                            <QuarterlyChartSection borderColor="#1EB564" />
                          </Row>
                        </div>
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
                <Row></Row>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const CountCard = ({ c, loading }) => {
  const [percentage, setPercentage] = useState();
  const [difference, setDifference] = useState();
  const { userProfile } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!c) return null;

    console.log(c);
    const diff = c._7Day - c._14Day;

    const percentage = parseInt(
      ((diff / (c._7Day + c._14Day)) * 100).toFixed(2)
    );

    setPercentage(!!percentage ? percentage : 0);
  }, [c]);

  //if (!c) return null
  return (
    <Card
      hoverable
      bordered={false}
      className="criclebox"
      style={{ height: "100%" }}
      loading={loading}
      onClick={() => {
        userProfile?.type != "SubAdmin" && c.url && navigate(c.url);
      }}
    >
      <div className="number">
        <Row align="middle" gutter={[24, 0]}>
          <Col span={24}>
            <span>{lang(c?.today)}</span>
            <Tooltip title={lang(c?.description)}><span className="ms-2"> {c?.icon}</span></Tooltip> 

            <Title level={3}>{lang(c?.title)}</Title>
          </Col>
          {/* <Col xs={6}>
            <div className="icon_box">
              <LineChartWithoutAxis
                isUp={percentage >= 0}
                points={[c?._14Day, c?._7Day]}
              />
            </div>
          </Col> */}
        </Row>
      </div>
    </Card>
  );
};

export default Home;
