import { Row, Col, Card, Table, DatePicker } from "antd";
import React, { useState, useEffect } from "react";

import lang from "../../helper/langHelper";
const { RangePicker } = DatePicker;

function Index({ data, loading }) {

  const [list, setList] = useState(data);
  const [pagination, setPagination] = useState(5);

  useEffect(() => {
    if (!data) return;
    setList(data);
    setPagination(data?.length);
    console.log("data ::", data);
  }, [data]);

  const columns = [
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name}</span> : "-";
      },
    },
    {
      title: lang("Phone Number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { mobile_number, country_code }) => {
        return mobile_number && country_code
          ? `(${country_code}) ${mobile_number}`
          : "-";
      },
    },
    {
      title: lang("Email"),
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? email : "-";
      },
    },
    // {
    //   title: lang("Total Views"),
    //   dataIndex: "most_view",
    //   key: "most_view",
    //   render: (_, { most_view }) => {
    //     return most_view ? most_view : "0";
    //   },
    // },
    {
      title: lang("Total Call Count"),
      dataIndex: "call_count",
      key: "call_count",
      render: (_, { call_count }) => {
        return call_count ? call_count : "0";
      },
    },


  ];

  return (
    <>
      <div className="tabled bannerMain">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                    // showSizeChanger: true,
                    // pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
